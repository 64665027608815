const state = {
    locale: localStorage.getItem('locale') || 'en',
    cartItemsCount: 0 // Add a state for cart items count
};

const actions = {
    handleMessage({ commit, state }, { message, type = 'error' }) {
        console.log("Locale:", state.locale);
        console.log("Original Message:", message.trim());

        let displayMessage = message.trim();

        if (state.locale.toUpperCase() === 'EN') {
            const translations = {
                '請先登錄': 'Please login first',
                '没有可结算的商品': 'No items to checkout',
                '沒有可結算的商品,或支付超時': 'No items to checkout, or payment timeout',
                '參數错误': 'Parameter error',
                '新增預約成功': 'Add appointment successfully',
                '該時段已預訂': 'This time slot has been booked',
                '请重新登录': 'Please login again',
                '操作失敗: 某商品庫存不足': 'Operation failed: Insufficient inventory of some items',
                '支付成功': 'Payment successful',
                // Additional translations can be added here
            };

            Object.entries(translations).forEach(([chinese, english]) => {
                let regex = new RegExp(chinese.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g');
                if (displayMessage.includes(chinese)) {
                    console.log(`Translating '${chinese}' to '${english}'`); // Debug output
                    displayMessage = displayMessage.replace(regex, english);
                }
            });
        }

        // Commit the message and its type to the mutation
        commit('displayMessage', { message: displayMessage, type });
    },

    // Action to update the cart items count
    updateCartItemsCount({ commit }, count) {
        commit('setCartItemsCount', count); // Commit the mutation to update the count
    }
};

const mutations = {
    displayMessage(state, { message, type }) {
        // Use the appropriate method to display the message based on its type
        if (type === 'success') {
            this._vm.$message.success(message); // Replace with your actual success message method
        } else {
            this._vm.$message.error(message); // Replace with your actual error message method
        }
    },

    // Mutation to update the cart items count
    setCartItemsCount(state, count) {
        state.cartItemsCount = count;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
